.filterPopover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border: 0;

  > div:first-child {
    // arrow
    top: 100%;

    &:before {
      display: none;
    }
  }
}

.filterIcon {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 6px;

  &:hover {
    color: #666;
  }
}

.legendRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 5px;
}

.tapButton,
.disabledTapButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  transition: background-color 0.15s ease-in-out;

  svg {
    width: 35px;
    height: 35px;
  }
}

.tapButton:focus,
.disabledTapButton:focus {
  outline: 0;
  background-color: #e9e9e9;
}

.disabledTapButton {
  color: #999;
}

.filterRow {
  height: 45px;
  padding-left: 15px;
}

.buttonRow {
  padding: 5px 10px 10px 10px;

  > div:first-child {
    border-right: 1px solid #ddd;
  }

  > div:last-child {
    border-left: 1px solid #ddd;
  }
}

.resetButtonRow {
  display: flex;
  justify-content: center;
  margin: 0;
}

.resetButton {
  font-size: 0.85rem;
  background-color: #ddd;
  border-color: #ddd;
  padding: 3px 20px;
  border-radius: 15px;
  color: #333;
  text-transform: uppercase;
}

.desktopPopover {
  width: 30vw;
  min-width: 400px;
  max-width: none;
  left: 0.7rem !important;
  margin-bottom: 0.4rem !important;

  :global .arrow:after {
    bottom: 1px;
    left: -0.5rem !important;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }

  .tapButton:hover,
  .disabledTapButton:hover {
    background-color: #e9e9e9;
  }
}

.mobilePopover {
  width: calc(100vw - 10px);
  max-width: none;
}
