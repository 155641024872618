.desktopSearch {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding: 1rem 0 0 1rem;
  pointer-events: none;

  .searchBarContainer {
    width: 30vw;
    min-width: 400px;
  }

  .searchInput {
    width: 100%;
  }
}

.searchBarContainer {
  position: relative;

  &.hasDropdown {
    .searchInput {
      border-radius: 5px 5px 0 0;

      &:focus {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
      }
    }
  }
}

.mobileSearch {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding: 1rem 0 0 1rem;
  pointer-events: none;

  .searchBarContainer {
    width: 100%;
  }
}

.mobileSearchButton {
  margin: 1rem 0 0 1rem;
  height: 40px;
  width: 40px;
  opacity: 0.5;
  pointer-events: visibleStroke;
}

.mobileIcon {
  height: 100%;
  width: 100% !important;
}

.searchInput {
  flex: 1;
  margin: 0;
  border-radius: 5px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  animation-name: search-input-animation;
  animation-duration: 0.4s;
  animation-fill-mode: forward;
  animation-timing-function: ease-in-out;
  pointer-events: visibleStroke;
}
@keyframes search-input-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.mobileCloseButton {
  position: relative;
  margin: 0 0.5rem;
  height: 40px;
  width: 40px;
  padding: 0 10px;
  animation-name: close-button-animation;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  pointer-events: visibleStroke;
}
@keyframes close-button-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.autocompleteDropdown {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  pointer-events: painted;
  width: 100%;
  background-color: white;
  border-radius: 0 0 5px 5px;
  padding: 0.5rem;
  overflow: hidden;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #dadce0;

  & > div {
    cursor: pointer;
    border-radius: 5px;
    padding: 0.15rem 0.25rem;
    margin-bottom: 0.25rem;
    line-height: 1.25;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.suggestionItem,
.suggestionItemActive {
}

.suggestionItemActive {
  background-color: #e9e9e9;
}
