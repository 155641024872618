.infoList {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1rem 0 0.5rem 0;
  margin: 0 1rem;
  border-top: 1px solid #ccc;
}

.infoAttribute {
  width: 4rem;
  padding: 0 4px;
  text-align: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 30px;
  height: 30px;
  min-width: 30x;
  min-height: 30px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 5px;
}

.strikethrough {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 50%;
    margin-right: -1px;
    transform: rotate(45deg);
    width: 1px;
    height: 100%;
    background-color: #ccc;
  }
}

.infoIcon {
  max-width: 100%;
  max-height: 100%;
}

.iconLabel {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.5625rem;
  text-transform: uppercase;
  color: #333;
  line-height: 1;
}
