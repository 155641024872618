button {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.svg-inline--fa {
  transition: color 0.15s ease-in-out;
}

.page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
