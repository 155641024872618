:root {
  --info-main-image-height-min: 50px;
  --info-main-image-height-max: 200px;
  --tap-menu-margin: 5%;
}

.info-window-out {
  left: 50%;
  transform: translate(-50%, 100%);
  -webkit-transform: translate(-50%, 100%);
  -moz-transform: translate(-50%, 100%);
  -ms-transform: translate(-50%, 100%);
  -o-transform: translate(-50%, 100%);
  transition: 0.6s all ease-in-out;
  -webkit-transition: 0.6s all ease-in-out;
  -moz-transition: 0.6s all ease-in-out;
  -ms-transition: 0.6s all ease-in-out;
  -o-transition: 0.6s all ease-in-out;
  /*background-color: blueviolet;
    */
  opacity: 0;
}
.info-window-in {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.info-window-out-desktop {
  left: -50%;
  opacity: 0;
}
.info-window-in-desktop {
  left: 0;
  opacity: 1;
  /* background-color: burlywood; */
}

#tap-menu {
  height: fit-content;
  margin: 0 var(--tap-menu-margin) 5px 0;
}

/* Tap Image */
#tap-info-img-box {
  position: relative;
  width: 100%;
  height: 25vw;
  max-width: 100%;
  max-height: var(--info-main-image-height-max);
  min-width: 100%;
  min-height: var(--info-main-image-height-min);
}
#tap-info-img-box-desktop {
  position: relative;
  width: 100%;
  height: 25vw;
  max-width: 100%;
  max-height: var(--info-main-image-height-max);
  min-width: 100%;
  min-height: var(--info-main-image-height-min);
  padding: 10px 10px 0 10px;
}
#tap-info-img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
}
#tap-head-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
}
#tap-type-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 0 0 auto;
  margin: 0 10px 0 0;
  max-width: 15%;
}
#tap-type-icon {
  width: 50px;
  height: 50px;
}
#tap-type-icon svg {
  width: 100%;
  height: 100%;
}

/* Name & Address */

#org-name-and-address {
  align-self: center;
  flex: 2;
  margin-top: 5px;
}
#org-name-and-address-desktop {
  align-self: center;
  flex: 3;
}
#tap-organization-name {
  font-size: 1.1em;
  font-weight: 700;
  text-align: start;
  line-height: 1.125rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
#tap-info-address {
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0 0 0;
}

/* Hours */

#org-hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  flex: 3;
  /* background-color: darkcyan; */
}
#tap-info-org-status {
  text-align: center;
  color: green;
  font-size: 1em;
  font-weight: 700;
}

/* expanded */
#tap-info-hours-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
  align-self: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 0 3px 0 3px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  background-color: white;
  z-index: 100;
}
#tap-info-hours-container-placeholder {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  width: fit-content;
  box-sizing: border-box;
  align-self: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 0 3px 0 3px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
#hours-area {
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
  align-self: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  /* padding: 0 3px 0 3px; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /* background-color: coral; */
}
#tap-info-hours-list {
  position: absolute;
}
#current-hours {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  /* background-color: cadetblue; */
}
#current-hours-placeholder {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  width: fit-content;
}
#other-hours-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tap-hours-list-item {
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  /* background-color: lightgreen; */
}
#tap-hours-placeholder {
  visibility: hidden;
}

.tap-info-icon-img {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

/* Expand Arrow */
#arrow-description-toggle {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Description */
#tap-info-description {
  flex: 1;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transition: 0.6s all ease-in-out;
  -webkit-transition: 0.6s all ease-in-out;
  -moz-transition: 0.6s all ease-in-out;
  -ms-transition: 0.6s all ease-in-out;
  -o-transition: 0.6s all ease-in-out;
  line-height: 1.3125;
  font-size: 0.875rem;
}
