.headColumns{
    display: flex;
    justify-content: space-between;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    color: #0e1015;
    opacity: 1;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    width: 100%;
    white-space: nowrap;
    z-index: 20;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.menu {
  z-index: 100;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 66px;
}

.dropdown-item{
  font-size: 10px;
  background-color: white;
  z-index: 1000000;
}

.logoImage{
  width: 100%;
  height: auto;
  vertical-align: middle;
  opacity: 1;
}

.navbar-toggler{
  display: contents
}

@media (min-width: 992px) {
  .navbar-brand {
    margin-right: 80px !important;
  }
}
