.closeButton {
  // closeButton is desktop only
  position: absolute;
  z-index: 0;
  top: 50%;
  right: -36px;
  margin-top: -40px;
  border-radius: 0 4px 4px 0;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  .closeIconWrapper {
    width: 40px;
    height: auto;
    padding: 4px 14px 0 12px;
  }

  .closeIcon {
    width: 100%;
    height: 100%;
  }

  &:hover .closeIcon {
    color: #666;
  }
}

.walkTime {
  padding: 0 1rem 0.5rem 1rem;
  font-size: 12px;
  font-weight: 500;
}

.description {
  padding: 0.5rem 1rem;

  h3 {
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0;
  }

  .section {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mobileButtons {
  display: flex;
  justify-content: flex-end;
  margin: 0.25rem;
}
.mobileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0.25rem;
  border-radius: 50%;
  background-color: #999;
  transition: box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(#999, 0.3);
  }
}

.mobileDragbarArea {
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 2rem;
}

.mobileDragbar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.tapContent {
  position: relative;
  width: 100%;
  height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-y: auto;
}

.tapContentExpanded {
  position: relative;
  width: fit-content;
  max-width: 100%;
  margin: 0 5px 0 5px;
  border-radius: 5px;
  overflow: scroll;
}

.mobileContainer {
  position: absolute;
  bottom: 10px;
  width: 95%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);
  padding: 0 2px 0 2px;
  background-color: white;
  animation-name: info-modal-in;
  animation-duration: 0.6s;
  animation-fill-mode: forward;
  animation-timing-function: ease-in-out;
  transition: 0.6s all ease-in-out;

  .tapContent {
    margin: 0 5px 0 5px;
    width: fit-content;
  }
}
@keyframes info-modal-in {
  0% {
    bottom: -50%;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes info-modal-in-desktop {
  0% {
    left: -50%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
.desktopContainer {
  position: relative;
  width: 30%;
  min-width: 400px;
  max-height: calc(100vh - 220px);
  margin: 16px 0 0 16px;
  animation-name: info-modal-in-desktop;
  animation-duration: 0.6s;
  animation-fill-mode: forward;
  animation-timing-function: ease-in-out;
  transition: 0.6s all ease-in-out;

  .tapContent {
    max-height: calc(100vh - 220px);
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
}
