.pageText {
  width: 60%;
  margin: 0 auto;
  margin-top: 5%;
}

.blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}
